import backgroundImage from "~/assets/img/fork.jpg";

export function AuthLayout({ children }) {
  return (
    <>
      <div className="absolute flex h-full min-h-full w-full flex-1 justify-center overflow-hidden md:px-12 lg:px-0">
        <div className="relative z-10 flex w-full flex-1 flex-col bg-white shadow-2xl sm:justify-center  md:w-[38rem] md:flex-none">
          <div className="mx-auto w-full overflow-y-auto py-10 px-6 sm:px-32">{children}</div>
        </div>
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
          <img className="absolute inset-0 h-full w-full object-cover" src={backgroundImage} alt="branding_background" />
        </div>
      </div>
    </>
  );
}
